import Vue from 'vue'
import VueRouter from 'vue-router'
import eventbus from '@app/eventbus.js';
import navigation from '@app/navigation'
import user from '@app/user'
import fnExpose from '@lib/expose'

let routes = navigation.routesInRouterFormat();

Vue.use(VueRouter)

const routeUnknown =
{
    path: '*',
    name: 'Route onbekend',
    component: () => import('@/views/system/404.vue')
};
const _routes = [...routes, routeUnknown];

// memrouter is for resolving urls to routes.
const memrouter = new VueRouter({
    routes: routes
})

const router = new VueRouter({
    routes: _routes
})

/**
 * Provide an event interface to switch to another route
 */
eventbus.route.go.on( (route) => {
    try {
        router.push(route)
    }
    catch(e) {  
        console.error(e);
    }

});

eventbus.auth.loggedOut.on( () => router.push('/login') );
eventbus.route.get.on( (para) => para.route = router.currentRoute);

eventbus.route.resolve.on( (para) => {
    if (para && para.url) {
        var result = memrouter.resolve(para.url);
        if (result && result.resolved && result.resolved.matched && result.resolved.matched.length) {
            para.route = result.resolved;
        }
    }
})

/**
 * Authentication
 */

router.beforeEach( async (to, from, next) => {

    // isLoggedIn means that the user is loggedin, not that the user is still logged in. 
    // In the latter case, each request will lead to an authentication failure which wil get us logged out.
    // However, from router point of view we just need to know if we are logged in.
    const isLoggedIn = user.isLoggedIn();
    
    // does the route require authentication?
    // This is the default case, only when explicitely set to false, we do not require authentication. 
    const isAuthenticatedRoute = to.matched.some((record) => record.meta.requiresAuth !== false);

    console.log('route, isAuthenticatedRoute, isLoggedin', to.path, isAuthenticatedRoute, isLoggedIn)

    // The path to the login route.
    const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
    const unAuthorizedPath = { path: "/"};
  
    // If the route does not need authentication, fine. Proceed.
    if (!isAuthenticatedRoute) {
        console.log('next1')
        next(); 
        return; // verrrrry important
    }
//    if (!isAuthenticatedRoute || isLoggedIn) {
//        console.log('next1')
//        next(); 
//        return; // verrrrry important
//    }
 
     // If the user is logged in and the route is authorized but we can not access it, defer to other route.
     if (isLoggedIn && !to.meta.isAuthorized) {
         console.log('next2')
         next(unAuthorizedPath); 
         return; // very important
     }
 
     // If the user is logged in and can access the authorized route, proceed.
     if (isLoggedIn && to.meta.isAuthorized) {
         console.log('next3')
         next(); 
         return; // very important
     }
    
    // Otherwise check if the user is logged in but did e.g. refresh or come back after redirect  
    var result = await user.checkIsLoggedIn();
    if (result.action) {
        next(result.action);
        return;
    }

    console.log('Result of checkloggedin: ', result)
    if (user.isLoggedIn()) {
        console.log('next4')
        next();
        return; // very important
    }    

    console.log('deferring route')

    // otherwise, we are not logged in.
    next(loginQuery);

});

/*
router.beforeEach( (to, from, next) => {
    // isLoggedIn means that the user is loggedin, not that the user is still logged in. 
    // In the latter case, each request will lead to an authentication failure which wil get us logged out.
    // However, from router point of view we just need to know if we are logged in.
    const isLoggedIn = user.isLoggedIn();
    // does the route require authentication?
    // This is the default case, only when explicitely set to false, we do not require authentication. 
    const isAuthenticatedRoute = to.matched.some((record) => record.meta.requiresAuth !== false);

    console.log('route, isAuthenticatedRoute, isLoggedin', to.path, isAuthenticatedRoute, isLoggedIn)

      
    // If the route does not need authentication or the user is logged in, fine. Proceed.
    if (!isAuthenticatedRoute || isLoggedIn) {
        return; // Fine!
    }

    next()
})
*/
/* Once router v4 is used when switching to vue 3, the following is the implemetation:

router.beforeEach( async (to, from) => {
    // isLoggedIn means that the user is loggedin, not that the user is still logged in. 
    // In the latter case, each request will lead to an authentication failure which wil get us logged out.
    // However, from router point of view we just need to know if we are logged in.
    const isLoggedIn = user.isLoggedIn();
    // does the route require authentication?
    // This is the default case, only when explicitely set to false, we do not require authentication. 
    const isAuthenticatedRoute = to.matched.some((record) => record.meta.requiresAuth !== false);

    console.log('route, isAuthenticatedRoute, isLoggedin', to.path, isAuthenticatedRoute, isLoggedIn)

  
    // If the route does not need authentication or the user is logged in, fine. Proceed.
    if (!isAuthenticatedRoute || isLoggedIn) {
        return; // Fine!
    }

    // Otherwise check if the user is logged in but did e.g. refresh or come back after redirect  
//    await user.checkIsLoggedIn();
    if (user.isLoggedIn()) {
        return; // Fine!
    }    

    console.log('deferring route')
  
    // The path to the login route.
    const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
  
    // otherwise, we are not logged in.
    return loginQuery; // redirect to login.
  });
*/

 fnExpose(router, 'router')

export default router
