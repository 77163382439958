import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false

// Convert a single value to an array, leave an array as is.
window.toArray = (item) => Array.isArray(item||[]) ? (item||[]) : [item];

new Vue({  
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
