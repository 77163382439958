//
// Usage: 
//  import navigation from '@app/navigation'
//

import clsNavigation from '@/app/clsNavigation'
import fnExpose from '@shared/lib/expose'
import {ref} from 'vue'

let obj =  ref (new clsNavigation());
fnExpose(obj.value, 'system', 'navigation')

export default obj.value;