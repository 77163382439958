import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import '@/assets/css/style.scss'

Vue.use(Vuetify);

export default new Vuetify({
    font: {
        family: 'Nunito'
    },
    theme: {
        themes: {
            light:
            {
                primary: '#3F7AFF',
                
                // success: '#5bdba8',
                // error:   '#e8415a',
                // warning: '#ffac5e',
            },
//            dark: 
//            {
//                background: 'red'
//            }
        }
    }, 
    config: {
        dataIterator: {
            loadingText: 'Gegevens laden...'
        }    
    }

});
