import axios from "axios";
// import http from '@app/http'

/////////////////////////////////////////////////////////////////////////////////////////////////
// Specific Api for authorization.
//
// Note two things. 
// 1) We use our own http client
//    This is because we want to intercept errors ourselves. 
//    That is, during authorization we don't want messages popping up that e.g. login failed.
// 2) The implementation is very verbose.
//    We want to return a fixed set of result options to the caller. 
//    Therefore, we catch all errors and return result structures.
//
// Each call has the following standard result data: 
// {
//      success: true|false   
//      message: null| "A generic message"    <-- filled when success is false.
//      data: {...}                           <-- any data applicable for the call - if any
// 
// }
//
// In addition, extra data may be added per call. 
// For example, the login call adds an action field. 
// This field indicates whether a next action is required, e.g. two_factor authentication.
// 

///////////////////////////////////////////////////////////////////////////////////////////////
class clsApi {

    authClient = null;

    constructor() {
        this.authClient = axios.create({
            baseURL: process.env.VUE_APP_BASEURL,
            withCredentials: true, // required to handle the CSRF token
        });
        this.authClient.data = (response, msg) => {
            if (!response || !response.data) {
                throw (msg || "Geen data ontvangen") 
            }
            return response.data;
        }
    }

    /**
     * Get the first specified error from an exception. 
     * The exception looks like: 
     *  {
     *      response: {
     *          data: {
     *              errors: {
     *                  email: [
     *                      "Geen gebruiker met dit e-mail adres"
     *                  ]
     *              }
     *          }
     *      }
     *  }
     * 
     * 
     * @param {*} e 
     * @param {*} defaultMessage 
     * @returns 
     */
    getExceptionErrorMessage(e, defaultMessage) {
        if (e && e.response && e.response.data && e.response.data.errors && e.response.data.errors) {
            var errors = e.response.data.errors;            
            var msg = null;
            for (var key in errors) {
                var value = errors[key];
                if (value && value.length && value[0]) {
                    return value[0];
                }
            }
        } 
        return defaultMessage;
    }

    /**
     * Get a message from a successful response or the default message.
     * @param {} response 
     * @param {*} defaultMessage 
     * @returns 
     */
    getResponseSuccessMessage(response, defaultMessage) {
        if (response && response.data && response.data.message) {
            return response.data.message;
        }
        return defaultMessage;       
    }

    /**
     * Override to execute any code when exception occured. Mainly for debugging.
     * 
     * @param {*} e 
     */
    onException(e) {
       
    }

    /**
     * Login with the provided credentials. 
     * Besides the success/message components, the result may contain an action field. 
     * The action field is null when no futher action is required, and the user is logged in.
     * Otherwise, it specifies a route which must be executed next. 
     * The action options are: 
     *      '/two-factor'            - two factor must be input
     *      '/configure-two-factor'  - the use must configure a two-factor authentication method. 
     * 
     * @param {*} payload 
     * @returns 
     */
    async login(payload) {
        
        var result = {
            success: true,
            message: null, 
            action: null
        }
        try {
            await this.authClient.get("/sanctum/csrf-cookie");
        }
        catch (e) {
            this.onException(e);
            result.success = false;
            result.message = "Er trad een fout op tijdens de pre-login";
            return result;
        }
        try {
            var response = await this.authClient.post("/login", payload);
            var loginResult = this.authClient.data(response, "Geen data ontvangen")
            console.log('clsAuthApi - loginResult: ', loginResult)
            if (loginResult.two_factor) {
                result.action = "two-factor";
            }
            else if (process.env.VUE_APP_SHOULD_TWO_FACTOR)  {
                result.action = "configure-two-factor";
            }
            else if (loginResult.select_administration) {
                result.action = "select-administratie";
            }
            return result;
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het inloggen is niet gelukt.");
            result.success = false;
            return result;
        }

    }
    
    /**
     * Get the authorized user
     * Returns just the success/message response.
     * @returns 
     */
    async getAuthUser() {
        var result = {
            success: false,
            message: null,
            data: null
        }

        try {
            var response = await this.authClient.get("/api/user/load");
            result.data = this.authClient.data(response, "Geen data ontvangen")
            result.success = true;
        }
        catch (e) {
            this.onException(e);
            result.message = "Het laden van de gebruikersgegevens is niet gelukt.";
        }
        return result;
    }

    /**
     * Logout. Ask the server to forget our session.
     * Since we use http only cookies, we can not modify clientside.
     */
    logout() {
        try {
            this.authClient.post("/logout");
        }
        catch (e) {
            this.onException(e);
            console.error('Error logging out. Logging out anyway.', e)
            // If we can not reach the server, or whatever error occurs, 
            // we ignore it. On clientside, we remove our data.        
        }
        finally {
            // document.cookie = "opt_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
    }

    /**
     * The user forgot the password. 
     * We generate a reset link and send it to the filled email.
     * @param {*} payload 
     * @returns 
     */
    async forgotPassword(payload) {
        var result = {
            success: false,
            message: null,
        }

        try {
            await this.authClient.get("/sanctum/csrf-cookie");
            var response = await this.authClient.post("/forgot-password", payload);
            result.success = true;
            result.message = this.getResponseSuccessMessage(response, "De wachtwoord herstel e-mail is verstuurd.")
        }
        catch (e) {            
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Wanneer u een geldig email adres heeft ingevuld ontvangt u binnen enkele minuten u wachtwoord herstel link.");
        }
        return result;
    }

    /**
     * Reset the password
     * @param {*} payload 
     * @returns 
     */
    async resetPassword(payload) {
        var result = {
            success: false,
            message: null,
        }
        try {
            await this.authClient.get("/sanctum/csrf-cookie");
            var response = await this.authClient.post("/reset-password", payload);
            result.success = true;
            result.message = this.getResponseSuccessMessage(response, "Het wachtwoord van uw account is gewijzigd.");
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het resetten van het wachtwoord is niet gelukt. Wellicht is de herstel-link verlopen of zijn de ingevoerde gegevens niet correct.");
        }

        return result;
    }

    /**
     * Confirm a password for an already logged in user. 
     * 
     * @param {*} password 
     * @returns 
     */
    async confirmPassword(payload) {
        var result = {
            success: false,
            message: null,
        }
        try {
            await this.authClient.post("/user/confirm-password", payload);
            result.success = true;
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het wachwoord was niet correct.");
        }

        return result;
    }

    /**
     * Update the current user password.
     * @param {*} payload 
     * @returns 
     */
    async updatePassword(payload) {
        var result = {
            success: false,
            message: null,
        }
        try {
            var response = await this.authClient.put("/user/password", payload);
            result.success = true;
            result.message = this.getResponseSuccessMessage(response, "Het wachtwoord van uw account is gewijzigd.");
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het wijzigen van uw wachtwoord is niet gelukt.");
        }
        return result;
    }

    /**
     * Update the current user name.
     * @param {*} payload 
     * @returns 
     */
    async updateUsername(payload) {
        var result = {
            success: false,
            message: null,
        }
        try {
            var response = await this.authClient.post("/api/user/updateusername", payload);
            result.success = true;
            result.message = this.getResponseSuccessMessage(response, "De gebruikersnaam is gewijzigd.");
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het wijzigen van de gebruikersnaam is niet gelukt.");
        }
        return result;
    }

    updateUser(payload) {
        var result = {
            success: false,
            message: null,
        }
        try {
            var response = this.authClient.put("/user/profile-information", payload);
            result.success = true;
            result.message = this.getResponseSuccessMessage(response, "De naam is gewijzigd.");
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het wijzigen van de naam is niet gelukt.");
        }
        return result;
    }


    /**
     * Enable 2factor authentication
     * This call makes sure that in the backend, the necesary data is generated.
     * @returns 
     */
    async enableTwoFactor() {
        var result = {
            success: false,
            message: null,
        }

        try {
            var response = await this.authClient.post("/user/two-factor-authentication");
            result.success = true;
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het inschakelen van 2-factor authenticatie is mislukt.");
        }

        return result;
    }

    /**
     * Confirm 2factor authentication
     * After 2factor authentication is enabled, activating it is done in two steps. 
     * One for checking the first entered code, then explicitely confirm the action.
     * @returns 
     */
    async confirmTwoFactor(code) {
        var result = {
            success: false,
            message: null,
        }        
        try {
            var response = await this.authClient.post("/user/confirmed-two-factor-authentication", {code: code});
            result.success = true;
            result.message = this.getResponseSuccessMessage(response, "2-factor authenticatie is ingeschakeld");
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het bevestigen van 2-factor authenticatie is mislukt.");
        }

        return result;    
    }

    /**
     * Remove 2factor authentication
     * @returns 
    */
    async removeTwoFactor() {
        var result = {
            success: false,
            message: null,
        }

        try {
            var response = await this.authClient.delete("/user/two-factor-authentication");
            result.success = true;
            result.message = this.getResponseSuccessMessage(response, "2-factor authenticatie is uitgeschakeld");
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het uitschakelen van 2-factor authenticatie is mislukt.");
        }
        return result;

    }

    /**
     * 
     */
    async getTwoFactorQR() {
        var result = {
            success: false,
            message: null,
            data: null
        }

        try {
            var response = await this.authClient.get("/user/two-factor-qr-code");
            result.data = this.authClient.data(response, null)
            if (!result.data || !result.data.svg) {
                result.message="Het laden van de Two Factor gegevens is mislukt.";
            }
            else {
                result.success = true;
            }
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het laden van de Two Factor gegevens is niet gelukt.");
        }
        return result;
    }
    

    /**
     * Execute the two factor challenge.
     * code: the authentication code.
     * 
     * @param {*} code 
     * @param {*} recovery_code 
     * @returns 
     */
    async twoFactorChallenge(code, recovery_code) {
        var result = {
            success: false,
            message: null,
        }

        try {
            var response = await this.authClient.post("/two-factor-challenge", {code: code});
            result.success = true;
            result.message = this.getResponseSuccessMessage(response, "Het wachtwoord van uw account is gewijzigd.");
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het controleren van de 2-factor code is mislukt.");
        }
        return result;
    }

    /**
     * In case extra headers are to be included when loading app data, overwrite the following method.
     * 
     * @returns 
     */
    getHeadersForLoadAppData() {
        return {};
    }

    /**
     * Load data required to let the application work.
     * It is assumed that api/data/load is the entry point for loading the data.
     * @returns 
     */
    async loadAppData() {
        var result = {
            success: false,
            message: null,
        }

        try {
            var headers = this.getHeadersForLoadAppData();
            var response = await this.authClient.get("api/data/load", {headers: headers});
            result.success = true;
            result.data = this.authClient.data(response, null)
        }
        catch (e) {
            this.onException(e);
            result.message = this.getExceptionErrorMessage(e, "Het laden van de applicatie is mislukt.");
        }
        return result;
    }


    async getTwoFactorRecoveryCodes() {
        await this.authClient.get("/user/two-factor-recovery-codes");
    }
    async resetTwoFactorRecoveryCodes() {
        await this.authClient.post("/user/two-factor-recovery-codes");
    }

    

    
    async registerUser(payload) {
        await this.authClient.get("/sanctum/csrf-cookie");
        return this.authClient.post("/register", payload);
    }

    sendVerification(payload) {
        return this.authClient.post("/email/verification-notification", payload);
    }

}

export default clsApi;