import clsCurrentuser from '@cls/clsCurrentuser'
import authApi from '@lib/authApi'

class clsCurrentuserExt extends clsCurrentuser {

    administrations = [];
    id_administration = null;

    /**
     * Logout
     */
    async logout() {
        this.administrations = [];
        this.id_administration = null;
        return await super.logout();
    }
    
    /**
     * Fill the data, and in addition to the default, we (might) have administrations.
     *  
     * @param {} data 
     */
    fill(data) {
        data = data ||{};
        super.fill(data);
        this.administrations = data.administrations || [];
        this.id_administration = data.id_administration || null;
    }

    /**
     * Do we have no administration at all?
     */
    get hasNoAdministration() {
        return !this.administrations.length;
    }
    /**
     * Do we have exactly one administration?
     */
    get hasSingleAdministration() {
        return this.administrations.length == 1;
    }
    /**
     * Do we have multiple administrations?
     */
    get  hasMultipleAdministrations() {
        return this.administrations.length > 1;
    }

    /**
     * Get the headers which should for user perspective be sent along to the server.
     * In our case, we want the current administration to be sent along. 
     * 
     * Note that the server guards the validness of the provided data.
     *  
     * @return 
     *      the headers which should be included on server calls. 
     *      In addition to the default headers, the active company is added.
     */
    getHeaders() {
        var headers = super.getHeaders() || {};
        if (this.id_administration) {
            headers["X-OPTI-ACTI"] = this.id_administration;
        }
        return headers;
    }

    /**
     * In Optimit, after loading the authenticated user, 2 situations: 
     *  1) The user has access to 1 administration --> load just that.
     *  2) The user has access to more administratios --> user should select an administration. 
     * 
     * @returns 
     */
    async selectAdministration(id) {
        this.id_administration = id;
        var result = await this.loadAppData();
        console.log('selectAdministration: result of loadappdata: ', result);
        return result;
    }

    /**
     * In Optimit, after loading the authenticated user, 2 situations: 
     *  1) The user has access to 1 administration --> load just that.
     *  2) The user has access to more administratios --> user should select an administration. 
     * 
     * @returns 
     */
    async getAuthUser() {
        var result = await authApi.getAuthUser();
        if (result && result.success) {
            this.fill(result.data);

            if (this.hasMultipleAdministrations) {
                result.action="select-administration";
            } else if (this.hasSingleAdministration) {
                return await this.selectAdministration(this.administrations[0].id);
            
            } else {
                result.action="no-administration";
            } 

        }
        else {
            this.logout();
        }
        return result;
    }

}

export default clsCurrentuserExt;
