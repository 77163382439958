
<template>
  <v-app>

    <router-view>
    </router-view>

  </v-app>
</template>

<script setup>
    import {ref} from 'vue'
    let drawer = ref(false);
</script>
