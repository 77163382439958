import clsNavigation from '@cls/clsNavigation'


class clsNavigationExt extends clsNavigation {

    /**
     * 
     * To adjust a default route, override this method as follows: 
     *     getDefaultRoutes() {
     *          var routes = super.getDefaultRoutes();
     *          routes.auth.login.component  =  () => import("@/views/auth/my_custom_login.vue"),        
     *          return routes
     *     }
     */

    getDefaultRoutes() {
        var routes = super.getDefaultRoutes();
        routes['auth.login'].component  =  () => import("@/views/auth/login.vue")
        routes['auth.forgotpwd'].component  =  () => import("@/views/auth/forgotpwd.vue")
        routes['auth.twofactor'].component  =  () => import("@/views/auth/twofactor.vue")
        routes['auth.resetpwd'].component  =  () => import("@/views/auth/resetpwd.vue")

        return routes;
    }

} 

export default clsNavigationExt;
