import string from '@lib/string'
import constants from '@app/consts'

import routes from '@/app/routes'
import fnActions from '@/app/actions'
import menu from '@/app/menu'
import eventbus from '@app/eventbus.js';



// The navigation component processes routes, actions and the main menu.. 
// Routes must be defined in @/lib/navigation/routes.js
// Actions must be defined in @/lib/navigation/actions.js
// The menu items must be defined in @/lib/navigation/menu.js
//
// Note that this component works in close cooperation with the vue router.
// 



 // The purpose of this class is two-fold
 //
 // 1) We want all definitions to be in one place. That is in the constants.navigation file. 
 // 2) We want the source for the menu's and for the router to be just one. 
 //    That means that we can make routing decisions with the menu in mind. 
 //    Example: 
 //     1) * A user has access to two administrations (a and b). 
 //          The user is logged into administration a and is in the 'facturen' page. 
 //        * Now the user selects administration b, which does not have access to the 'facturen' page.
 //          Instead of showing an error, the router can traverse the main menu items, find the first 
 //          authorized page and route to that page. 
 //     2) * A user is logged in and is in #/facturen/overzicht/open
 //        * The use changes the route to #/offertes/overzicht/open. 
 //          Now the router can do the same as above.
 //
 //
 // 
 // The class gives access to menu structure and to execution of actions. 
 // Menu: 
 //    - mainmenu        - the routes to show in the main menu. Can be empty []
 //    - fastcreatemenu  - the actions to show in the fast create menu. Can be empty [] 
 //    - accountmenu     - all routes for use in the accountmenu. ordered by authorized, then unauthorized
 //    - companymenu     - all items to show in the company menu in the accountmenu. Authorized only.
 //     
 
 class clsBase {
 
    routes = {};
    actions = {};
    routesByPath = {};
    actionsByPath = {};
    menuItems = {};

    _routesByPath = {};

    /**
      * Set the isAuthorized flag in all available meta data 
      */
    constructor() {
        this.setRoutes();
        this.setActions();
        this.setMenuItems();

        let self = this;
        eventbus.auth.loaded.on( () => {
            self.setRoutes();
            self.setActions();
            self.setMenuItems();
            eventbus.auth.navigation.loaded();
        });          
    }
 
    /**
     * Set the routes. 
     * To manipulate, override, call this implementation, than adjust.
     * To change the default routes, override getDefultRoutes
     * 
     */
    setRoutes() {
        var defaultRoutes = this.getDefaultRoutes()||{};
        this.routes = {...defaultRoutes, ...routes}; // {...{a:1,b:2}, ...{a:11,c:3}} => { a: 11, b: 2, c: 3 }     
        // Not only set the routes, but also store them on the path index, and set the authorization meta data. 
        for (var key in this.routes) {
            var route = this.routes[key];
            // Set the authorization both in the route and in the meta.
            route.isAuthorized = route.meta.isAuthorized = this.isAuthorized(route);
            this.routesByPath[route.path] = route;
        }
    }
    
    /**
     * Set the actions. 
     * To manipulate, override, call this implementation, than adjust.
     * To change the default actions, override getDefaultActions
     * 
     */
    setActions() {
        var defaultActions = this.getDefaultActions()||{};
        var actions = fnActions();
        this.actions = {...defaultActions, ...actions}; // {...{a:1,b:2}, ...{a:11,c:3}} => { a: 11, b: 2, c: 3 }
        // Also store the authorization meta data. 
        for (var key in this.actions) {
            var action = this.actions[key];
            // Set the authorization both in the action and in the meta.
            action.isAuthorized = action.meta.isAuthorized = this.isAuthorized(action);
        }
    }
    
    /**
     * Override to set menuItems 
     * The menu items are defined in @/lib/navigation/menu.js and look like: 
     * [
     *      menuitem1, 
     *      [item2_1, item2_2, item2_3]
     *      ...
     * ]
     * 
     *  A menu item will be part of the menu when the menu item is authorized.
     *  When an entry is an array of menu items, the first authorized item is used.
     */
    setMenuItems() {
        var result = [];
        (menu||[]).forEach( (line) => {
            var lines = Array.isArray(line) ? line : [line];
            for (var n = 0; n < lines.length; n++) {
                var item = lines[n];
                if (item.isAuthorized) {
                    result.push(item);
                    break;
                }
            }
        })
        this.menu = result;
    }

     /**
      * Method which checks whether access to an item (either action or route) is granted menu item is to the current user.
      * If- and when authorization should be granted on certains actions/routes, override this method.
      * 
      * @param {} item 
      * @returns 
      */
    isAuthorized(item) {
        return true;
    }
 
 
     /**
      * Get an route / menu item for a given menu item or url
      * E.g. getRoute(menuItems.product)
      * or   getRoute('company.products')
      * 
      * @param {} route_or_name  
      * @returns 
      */
    getRoute(route_or_name) {
        if (!route_or_name) {
            console.error('getRoute: empty action specified'); 
            return null;
        }
        if (route_or_name.meta) {
            return route_or_name;
        }
        var result = this.routes[route_or_name];
        if (result) {
            return result;
        }

        // The route can be provided as a url.
        // And the url can be something like '/log/errors/admin', which maps to route with path /log/errors/:application. 
        // We can just ask the router to find out if a route with this resolved url is available.
        var para = {url: route_or_name};
        eventbus.route.resolve(para);
        if (para.matchedpath) {
            result = this.routesByPath[para.matchedpath];
            if (result) {
                return result;
            }
        }

        console.error('getRoute: action not found', route_or_name); 
        return null;
     }

     /**
      * Get an action from the actions array by either name, otherwise, if the param is already an action, pass through.
      * 
      * @param {} name 
      * @returns 
      */
    getAction(action_or_name) {
        if (!action_or_name) {
            console.error('getAction: empty action specified'); 
            return null;
        }
        if (action_or_name.meta) {
            return action_or_name;
        }
        var result = this.actions[action_or_name];
        if (result) {
            return result;
        }
        console.error('getAction: action not found', action_or_name); 
        return null;
    }
 
    /**
     * Execute an action. 
     * return true when the action was or could be executed, false otherwise.
     * 
     * @param {*} action 
     * @param {*} payload 
     */
    executeAction(_action, payload, doNotExecute) {
         let action = this.getAction(_action);
         if (!action) {
            return false;
         }
         if(!action.isAuthorized) {
             eventbus.dialog.alert(constants.alerts.general.no_rights_action);
             return false;
         }
         if (doNotExecute) {
             return true; // We do not execute. However, we could have executed.
         }
         // Otherwise, we can execute the action. Even if no action event is configured for this action, 
         // we consider it successfull.
         // There are 3 standard actions: 
         // 1) open a dialog for editing an item
         // 2) open a dialog for creating an item
         // 3) execute an event via the eventbus
         
         if (action.dialogopen || action.dialogcreate || action.dialogcopy) {
            var modelName = string.coalesce(action.dialogopen, action.dialogcreate, action.dialogcopy);
            var event = null;
            if (action.dialogopen) { event = eventbus.dialog.open; }
            else if (action.dialogcreate) { event = eventbus.dialog.create; }
            else if (action.dialogcopy) { event = eventbus.dialog.copy; }

            let pl = payload || {}
            // Add extra payload defined by the action. E.g. when opening comment, the action can specify the type
            // for the comment already. It prevents scattering of this data througout the system.
            if (action.eventpayload) {
                for(var n in action.eventpayload) {
                    pl[n] = action.eventpayload[n];
                }
            }
            event(modelName, pl||{});

        } else if (action.event) {
             let pl = payload || {}
             // Add extra payload defined by the action. E.g. when opening comment, the action can specify the type
             // for the comment already. It prevents scattering of this data througout the system.
             if (action.eventpayload) {
                 for(var n in action.eventpayload) {
                     pl[n] = action.eventpayload[n];
                 }
             }
             action.event(pl||{});
         }
         return true;
    }

    /**
     * Get All Routes from the system in a flat format which is usable by the vue router. 
    */
    routesInRouterFormat() {
        let result = [];
        for (var key in this.routes) {
            result.push(this.routes[key]);
        }
        return result;
    }

    /**
     * Get the current route. We use the vue-router functionallity for this. 
     */
    getCurrentRoute() {
        var para = {};
        eventbus.route.get(para);
        // 1) Is this path matched by a wildcard?
        //    Note that this kind of makes the second path obsolete. 
        //    Keep it in just in case.
        if (para && para.route && para.route.matched && para.route.matched.length == 1 && para.route.matched[0].path) {
            var route = this.routesByPath[para.route.matched[0].path];
            if (route) {
                return route;
            }
        }
        // 2) Match the absolute path
        if (para && para.route && para.route.path) {
            var route = this.routesByPath[para.route.path];
            return route;
        }
        return null;
    }


    /**
     * Get the default routes. 
     * To adjust a default route, override this method as follows: 
     *     getDefaultRoutes() {
     *          var routes = super.getDefaultRoutes();
     *          routes.auth.login.component  =  () => import("@/views/auth/my_custom_login.vue"),        
     *          return routes
     *     }
     */
    getDefaultRoutes() {
        return {
            // Release Notes.
            'release.notes': {
                path        : '/releasenotes',  
                component   :  () => import("@/views/releasenotes.vue"),        
                meta        : {
                    requiresAuth: true, menu    : {text: 'Releasenotes', icon: 'log'}
                }
            },
            // Login is unauthorized.
            'auth.login': {
                path        : '/login',  
                component   :  () => import("@shared/ui/auth/views/Login.vue"),        
                meta        : {
                    requiresAuth: false, menu    : {text: 'Login', icon: 'login'}
                }
            },
            // forgot password is unauthorized.
            'auth.forgotpwd': { 
                    path        : '/forgot-password',  
                    component   :  () => import("@shared/ui/auth/views/ForgotPassword.vue"),        
                    meta        : {
                        requiresAuth: false, menu    : {text: 'Wachtwoord vergeten', icon: 'login'}
                    }
            },
            // two-factor is unauthorized as it is the last step before being authorized.
            'auth.twofactor': { 
                path        : '/two-factor',  
                component   :  () => import("@shared/ui/auth/views/LoginTwoFactor.vue"),        
                meta        : {
                    requiresAuth: false, menu    : {text: '2-factor', icon: 'security'}
                }
            },
            // reset password is unauthorized.
            'auth.resetpwd': { 
                path        : '/reset-password',  
                component   :  () => import("@shared/ui/auth/views/ResetPassword.vue"),        
                meta        : {
                    requiresAuth: false, menu    : {text: 'Reset wachtwoord', icon: 'login'}
                }
            },
            'auth.profile': { 
                path        : '/user/profiel',  
                component   :  () => import("@shared/ui/auth/views/user/Profile.vue"),        
                meta        : {
                    menu    : {text: 'Profiel', icon: 'user'}
                }
            },
            'auth.security': { 
                path        : '/user/security',  
                component   :  () => import("@shared/ui/auth/views/user/Security.vue"),        
                meta        : {
                    menu    : {text: 'Beveiliging', icon: 'security'}
                }
            },                
        };
    }
    
    /**
     * Get the default actions. 
     * To adjust a default action, override this method as follows: 
     *     getDefaultActions() {
     *          var actions = super.getDefaultActions();
     *          actions.auth.changepassword.text  = "Verander paswoord";
     *          return actions
     *     }
     */
    getDefaultActions() {
        return {
            'auth.changepassword': {
                type: 'security', text: 'Verander wachtwoord', event: eventbus.dialog.auth.changepassword,
                meta: {},
            },
            'auth.changeusername': {
                type: 'security', text: 'Verander gebruikersnaam', event: eventbus.dialog.auth.changeusername,
                meta: {},
            },
            'auth.setup2fa': {
                type: 'security', text: '2factor authenticatie', event: eventbus.dialog.auth.setup2fa,
                meta: {},
            },
        };
    }
 }
 
 export default clsBase;