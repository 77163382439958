//
// All routes defined specific for this application.
//

const fnNotImplemented = () => import("@/views/system/underconstruction.vue")

const routes = {

    
    'select-administration': { 
        path        : '/select-administration',  
        component   :  () => import("@/views/auth/select-administration.vue"),
        meta        : {
            menu    : {text: 'Icons', icon: 'dashboard'},
        }
    },

    'optitest': { 
        path        : '/optitest',  
        component   :  () => import("@shared/views/optitest/Icons.vue"),        
        meta        : {
            menu    : {text: 'Icons', icon: 'dashboard'},
        }
    },
    'optitest.icons.opticon': { 
        path        : '/optitest/icons/opticon',  
        component   :  () => import("@shared/views/optitest/Icons.vue"),        
        meta        : {
            menu    : {text: 'Icons', icon: 'dashboard'},
        }
    },
    'optitest.icons.types': { 
        path        : '/optitest/icons/types',  
        component   :  () => import("@shared/views/optitest/IconsByType.vue"),        
        meta        : {
            menu    : {text: 'Icons', icon: 'dashboard'},
        }
    },
    'optitest.design': { 
        path        : '/optitest/design',  
        component   :  () => import("@/views/optitest/design.vue"),        
        meta        : {
            menu    : {text: 'Design', icon: 'example'},
        }
    },
    'optitest.test': { 
        path        : '/optitest/test',  
        component   :  () => import("@/views/optitest/test.vue"),        
        meta        : {
            menu    : {text: 'Test', icon: 'example'},
        }
    },

    'dashboard': { 
        path        : '/',  
        component   :  () => import("@/views/dashboard/dashboard.vue"),        
        meta        : {
            menu    : {text: 'Dashboard', icon: 'dashboard'},
        }
    },


}

export default routes;